@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* ------ Add your CSS below this line (or @import your other CSS files). ------- */
.sr .load-hidden {
  visibility: hidden;
}

.pd-animate-circle.class-one {
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  transform-origin: center center;
  animation: normal;
  opacity: 0;
  transform: scale(0.8);
}
.pd-animate-circle.class-one.visible {
  animation: pop 0.5s linear 1;
  opacity: 1;
}

@keyframes pop{
  50%  {transform: scale(1);}
}


/*!
  purgecss start ignore
*/
.branded-landing-page-content {
  max-width: none;
  margin: 70px auto 0px;
  padding: 0;
}
.htmlfragment.aem-GridColumn {
  padding: 0!important;
}

.prose p {
  font-size: inherit!important;
}

/*!
  purgecss end ignore
*/


